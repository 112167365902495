import { createRouter, createWebHashHistory } from 'vue-router'
import store from '@/store'
import { h } from 'vue'

const Layout = () => import('@/views/Layout')
const Home = () => import('@/views/home')
const PlayVideo = () => import('@/views/home/playvideo.vue')
const JianKongVideo = () => import('@/views/home/jiankongvideo.vue')
const About = () => import('@/views/home/about.vue')
const TrustCenter = () => import('@/views/home/trustCenter.vue')
const ConNews = () => import('@/views/home/connews.vue')
const Knowledge = () => import('@/views/home/knowledge.vue')
const HyNew = () => import('@/views/home/hynew.vue')
const CsList = () => import('@/views/home/csList.vue')
const NewInfo = () => import('@/views/home/newInfo.vue')
const Contact = () => import('@/views/home/contact.vue')
const SlInfo = () => import('@/views/home/slinfo.vue')
const Center = () => import('@/views/home/center.vue')
const Institute = () => import('@/views/home/institute.vue')
const kInfo = () => import('@/views/home/kInfo.vue')

const TopCategory = () => import('@/views/category/index')
const SubCategory = () => import('@/views/category/sub')
const Goods = () => import('@/views/goods/index')
const Cart = () => import('@/views/cart/index')

const Login = () => import('@/views/login/index')
const LoginCallback = () => import('@/views/login/callback')

const Checkout = () => import('@/views/member/pay/checkout')
const Pay = () => import('@/views/member/pay/index')
const PayResult = () => import('@/views/member/pay/result')

const MemberLayout = () => import('@/views/member/Layout')
const MemberHome = () => import('@/views/member/home')
const MemberOrder = () => import('@/views/member/order')
const MemberOrderDetail = () => import('@/views/member/order/detail')

// 路由规则
const routes = [
  // 一级路由布局容器
  {
    path: '/',
    component: Layout,
    children: [
      { path: '/', component: Home },
      { path: '/playvideo', component: PlayVideo },
      { path: '/jiankongvideo', component: JianKongVideo },
      { path: '/about', component: About },
      { path: '/trustcenter', component: TrustCenter },
      { path: '/connews', component: ConNews },
      { path: '/knowledge', component: Knowledge },
      { path: '/hynew', component: HyNew },
      { path: '/csList', component: CsList },
      { path: '/newInfo', component: NewInfo },
      { path: '/contact', component: Contact },
      { path: '/slinfo', component: SlInfo },
      { path: '/center', component: Center },
      { path: '/institute', component: Institute },
      { path: '/kinfo', component: kInfo },
    ]
  }
]

// vue2.0 new VueRouter({}) 创建路由实例
// vue3.0 createRouter({}) 创建路由实例
const router = createRouter({
  // 使用hash的路由模式
  history: createWebHashHistory(),
  routes,
  // 每次切换路由的时候滚动到页面顶部
  scrollBehavior() {
    // vue2.0  x  y  控制
    // vue3.0  left  top 控制
    return { left: 0, top: 0 }
  }
})

// 前置导航守卫
router.beforeEach((to, from, next) => {
  // 需要登录的路由：地址是以 /member 开头
  const { profile } = store.state.user
  if (!profile.token && to.path.startsWith('/member')) {
    return next('/login?redirectUrl=' + encodeURIComponent(to.fullPath))
  }
  next()
})

export default router
