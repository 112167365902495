<template>
  <!-- 一级路由 -->
  <RouterView />
</template>
<script>
// 页面等比例放大缩小
// import DevicePixelRatio from './utils/devicePixelRatio';
export default {
  name: 'App',
  data() {
    return {};
  },
  // created() {
  //   new DevicePixelRatio().init();
  //   // 重新打开网页设置
  //   // window.localStorage.setItem('mao', false);
  // }
};
</script>
<style>
::-webkit-scrollbar {
  /*隐藏滚轮*/
  display: none;
}
</style>
