// 首页
export default {
  namespaced: true,
  state () {
    return {
      type: window.localStorage.getItem('ltype') || '繁体中文'
    }
  },
  mutations: {
    // 修改用户信息，payload就是用户信息对象
    setType (state, payload) {
      state.type = payload;
      window.localStorage.setItem('ltype', payload)
    }
  }
}
